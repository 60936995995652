import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

import moment from "moment-timezone";

// layouts

const Admin = lazy(() => import("layouts/Admin.js"));
const Auth = lazy(() => import("layouts/Auth.js"));

// views without layouts
const Landing = lazy(() => import("views/Landing.js"));
const Personal = lazy(() => import("views/Personal.js"));
const PersonalRow = lazy(() => import("views/PersonalRow.js"));
const Profile = lazy(() => import("views/Profile.js"));
const Index = lazy(() => import("views/Index.js"));

const BedInformations = lazy(() => import("views/bed-informations/BedInformationsAdminPublic.js"));

moment().tz("Asia/Jakarta").format();
moment.tz.setDefault("Asia/Jakarta");

ReactDOM.render(
  <Suspense fallback={<div>Sedang memuat...</div>}>
    <BrowserRouter>
      <Switch>
        {/* add routes with layouts */}
        <Route path="/admin" component={Admin} />
        <Route path="/auth" component={Auth} />
        {/* add routes without layouts */}
        <Route path="/personal" exact component={Personal} />
        <Route path="/landing" exact component={Landing} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/bed-informations" exact component={BedInformations} />
        <Route path="/" exact component={Index} />
        <Route path="/personal/:id" exact component={PersonalRow} />
        {/* add redirect for first page */}
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
